import React from 'react'
import _shuffle from 'lodash.shuffle'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { Howl } from 'howler'

import Typograpy from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Refresh from '@mui/icons-material/Refresh'
import Slide from '@mui/material/Slide'

import ContentContainer from '../Commons/ContentContainer'
import { games } from './game-data'

import './Viral.css'

const game = games[0]

const Viral = () => {
  const [data, setData] = React.useState(game.data)
  const [selected, setSelected] = React.useState()
  const [shuffledQuestions, setShuffledQuestions] = React.useState(
    _shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
  )
  const [shuffledAnswers, setShuffledAnswers] = React.useState(
    _shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
  )

  const win = React.useMemo(() => data.every((data) => data.correct), [data])

  React.useEffect(() => {
    handleRefresh()
  }, [])

  const handleRefresh = () => {
    setData(
      data.map((data) => ({
        ...data,
        correct: false
      }))
    )
    setSelected()
    setShuffledAnswers(_shuffle(shuffledAnswers))
    setShuffledQuestions(_shuffle(shuffledQuestions))
  }

  const handleSelectQuestion = (item) => {
    setSelected(item)
  }

  const handleSelectAnswer = (answer) => {
    if (answer) {
      setData((data) =>
        data.map((data) => {
          if (data.answer === answer) {
            data.correct = true
          }
          return data
        })
      )
    }
    setSelected()
  }

  return (
    <ContentContainer>
      <div className='viral-container'>
        <div className='game-container'>
          <Typograpy component='h6' variant='h6'>
            {game.title}
          </Typograpy>
          <Slide timeout={{ exit: 0 }} direction='up' in={win} mountOnEnter unmountOnExit>
            <div className='win-content'>
              <Typograpy component='body' variant='h6'>
                🎉🎉 Jroh 🎉🎉
              </Typograpy>
              <IconButton onClick={handleRefresh}>
                <Refresh fontSize='large' />
              </IconButton>
            </div>
          </Slide>
          <Slide
            timeout={{ appear: 600, enter: 600, exit: 600 }}
            direction='up'
            in={!win}
            mountOnEnter
            unmountOnExit
          >
            <div className='game-content'>
              <div className='questions-container'>
                {shuffledQuestions.map((index) => (
                  <QuestionCard
                    key={data[index].question}
                    question={data[index].question}
                    answer={data[index].answer}
                    sound={data[index].sound}
                    correct={data[index].correct}
                    selected={selected?.question === data[index].question}
                    onSelect={handleSelectQuestion}
                  />
                ))}
              </div>
              <div className='answers-container'>
                {shuffledAnswers.map((index) => (
                  <AnswerCard
                    key={data[index].answer}
                    answer={data[index].answer}
                    correct={data[index].correct}
                    selected={selected?.answer === data[index].answer}
                    onSelect={handleSelectAnswer}
                  />
                ))}
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </ContentContainer>
  )
}

const AnswerCard = ({ answer, correct = false, selected = false, onSelect = () => { } }) => {
  const matches = useMediaQuery('(max-width:600px)')

  const handleDragOver = (event) => {
    if (!correct) {
      event.preventDefault()
    }
  }

  const handleClick = () => {
    if (selected) {
      onSelect(answer)
    } else {
      onSelect()
    }
  }

  const handleDrop = handleClick

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
      className={`answer-item ${correct ? 'answer-correct' : ''}`}
      key={answer}
    >
      <Typograpy component='body' variant={matches ? 'h6' : 'h4'}>
        {answer}
      </Typograpy>
    </div>
  )
}

const QuestionCard = ({
  question,
  answer,
  correct = false,
  selected = false,
  sound,
  onSelect = () => { }
}) => {
  const [dragging, setDrag] = React.useState(false)
  const matches = useMediaQuery('(max-width:600px)')

  const howler = new Howl({ src: [sound], html5: true })

  const handleDragStart = () => {
    onSelect({ question, answer, dragging: true })
    setDrag(true)
  }

  const handleDragEnd = () => {
    setDrag(false)
  }

  const handleClick = () => {
    howler.play()
    if (!correct) {
      onSelect({ question, answer })
    }
  }

  return (
    <div
      draggable={!correct}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onClick={handleClick}
      className={`question-item ${correct ? 'answer-correct' : 'answer-not-correct'} ${selected ? 'answer-selected' : ''
        } ${dragging ? 'dragging' : ''}`}
    >
      <Typograpy component='body' variant={matches ? 'h6' : 'h4'}>
        {question}
      </Typograpy>
    </div>
  )
}

QuestionCard.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  correct: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  sound: PropTypes.string
}

AnswerCard.propTypes = {
  answer: PropTypes.string.isRequired,
  correct: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func
}

export default Viral
