import React from 'react'
import PropTypes from 'prop-types'

import Container from '@mui/material/Container'

import './ContentContainer.css'

const ContentContainer = ({ children, className }) => {
  return (
    <Container maxWidth='xl'>
      <div className={`content-container ${className || ''}`}>{children}</div>
    </Container>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
}

export default ContentContainer
