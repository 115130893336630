import { styled } from '@mui/material/styles'
import Box from '@mui/material/Container'

import './AppFooter.css'

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white
}))

const AppFooter = () => {
  return (
    <div className='footer-container'>
      <Footer maxWidth='xl'>Copyright © SabohMasa 2023</Footer>
    </div>
  )
}

export default AppFooter
