import sa from '../../../assets/sounds/1.mp3'
import dua from '../../../assets/sounds/2.mp3'
import lhee from '../../../assets/sounds/3.mp3'
import peuet from '../../../assets/sounds/4.mp3'
import limong from '../../../assets/sounds/5.mp3'
import nam from '../../../assets/sounds/6.mp3'
import tujoh from '../../../assets/sounds/7.mp3'
import lapan from '../../../assets/sounds/8.mp3'
import sikureueng from '../../../assets/sounds/9.mp3'
import siploh from '../../../assets/sounds/10.mp3'

export const games = [
  {
    title: 'Lumbôi lam basa Acèh',
    data: [
      {
        question: 'sa',
        answer: '1',
        correct: false,
        sound: sa
      },
      {
        question: 'dua',
        answer: '2',
        correct: false,
        sound: dua
      },
      {
        question: 'lhèë',
        answer: '3',
        correct: false,
        sound: lhee
      },
      {
        question: 'peuët',
        answer: '4',
        correct: false,
        sound: peuet
      },
      {
        question: 'limong',
        answer: '5',
        correct: false,
        sound: limong
      },
      {
        question: 'nam',
        answer: '6',
        correct: false,
        sound: nam
      },
      {
        question: 'tujôh',
        answer: '7',
        correct: false,
        sound: tujoh
      },
      {
        question: 'lapan',
        answer: '8',
        correct: false,
        sound: lapan
      },
      {
        question: 'sikureuëng',
        answer: '9',
        correct: false,
        sound: sikureueng
      },
      {
        question: 'siplôh',
        answer: '10',
        correct: false,
        sound: siploh
      }
    ]
  }
]
