import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

import Typography from '@mui/material/Typography'

import ContentContainer from '../Commons/ContentContainer'

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import './Intro.css'

const Intro = () => {
  return (
    <ContentContainer>
      <div className='embed-container'>
        <LiteYouTubeEmbed id='rItxle3w_cA' title='Atjèh Boikot Pemilu' />
      </div>
      <div className='text-container'>
        <div className='with-bottom-margin'>
          <Typography component='h1' variant='h6'>
            Ranub Sigapu
          </Typography>
        </div>
        <Typography paragraph>Assalamu{"'"}alaikum warahmatullahi wabarakatuh,</Typography>
        <Typography paragraph>
          Trôh an nibak thôn 2023, yoh internèt ka ditamong bak rata sagoë di Nanggroë Acèh, mantong
          dit takalon wèbsait-wèbsait nyang meutuléh lam basa Acèh. Bahkan jeuët takheun chit hana
          meusaboh pih nyang na. Meusabab nyan keuh, deungon seungaja, kamoë meupeugot wèbsait
          SabohMasa nyoë sibagoë catatan seujarah geutanyoë bansa Acèh lam dônya maya.
        </Typography>
        <Typography paragraph>
          Geutanyoë Acèh ka meureutôh thôn tameu-udép lam jajahan bangsa aséng dan ramè diantara
          geutanyoë nyang katapeutuwo cara-cara meu-udép sibagoë saboh bangsa nyang meurdéhka.
          Nakeuh tujuan kamoë, meulalui wèbsait nyoë, untôk beujeuët tapeugisa keulai tata cara udép
          sibagoë ureuëng Acèh nyang dithèë le masyarakat dônya, sibagoë waréh keuturunan nibak
          éndatu geutanyoë nyang bak saboh masa uroë jéh that ditakôt le musoh, teurutam le
          peunjajah Beulanda. Meunan yoh uroë jéh, beujeuët meunan chit bak uroë nyoë dan uroë-uroë
          ukeuë.
        </Typography>
      </div>
    </ContentContainer>
  )
}

export default Intro
