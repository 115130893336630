import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Diversity3 from '@mui/icons-material/Diversity3'

import './AppBar.css'

const pages = {
  intro: {
    'title-ac': 'Peuturi droë',
    'title-en': 'About',
    path: 'intro'
  },
  news: {
    'title-ac': 'Haba barô',
    'title-en': 'News',
    path: 'news'
  },
  viral: {
    'title-ac': 'Teungoh viral',
    'title-en': 'Viral',
    path: 'viral'
  },
  contact: {
    'title-ac': 'Hubông kamoë',
    'title-en': 'Contact',
    path: 'contact'
  }
}

const pagesKeys = Object.keys(pages)

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const navigate = useNavigate()
  const { la } = useParams()
  const titleKey = 'title-' + la

  React.useEffect(() => {
    if (!la) {
      navigate('/ac')
    }
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const createOnClickHandler = (path) => () => {
    const laPath = la || 'ac'

    if (!path) {
      navigate(laPath)
    } else {
      navigate(`${laPath}/${path}`)
    }
    handleCloseNavMenu()
  }

  return (
    <AppBar position='static' className='appbar-container'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Diversity3 sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant='h6'
            noWrap
            component='a'
            onClick={createOnClickHandler('')}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            SabohMasa
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pagesKeys.map((key) => (
                <MenuItem key={key} onClick={createOnClickHandler(pages[key].path)}>
                  <Typography textAlign='center'>{pages[key][titleKey]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Diversity3
            onClick={createOnClickHandler('')}
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
          />
          <Typography
            variant='h5'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            SabohMasa
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pagesKeys.map((key) => (
              <Button
                key={key}
                onClick={createOnClickHandler(pages[key].path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {pages[key][titleKey]}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
