import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import AppFooter from '../../AppFooter/AppFooter'

import './BodyContainer.css'

const BodyContainer = () => {
  const ref = React.useRef()
  const location = useLocation()

  React.useEffect(() => {
    ref?.current?.scrollTo(0, 0)
  }, [location])

  return (
    <div ref={ref} className='body-container'>
      <div className='page-container'>
        <Outlet />
      </div>
      <AppFooter />
    </div>
  )
}

export default BodyContainer
