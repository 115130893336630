import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AppBar from '../AppBar/AppBar'
import Home from '../AppContent/Home/Home'
import Intro from '../AppContent/Intro/Intro'
import News from '../AppContent/News/News'
import Viral from '../AppContent/Viral/Viral'
import Contact from '../AppContent/Contact/Contact'
import BodyContainer from '../AppContent/Commons/BodyContainer'

function AppRoutes() {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <AppBar />
            <BodyContainer />
          </>
        }
      >
        <Route path='/:la' element={<Home />} />
        <Route path='/:la/intro' element={<Intro />} />
        <Route path='/:la/news' element={<News />} />
        <Route path='/:la/viral' element={<Viral />} />
        <Route path='/:la/contact' element={<Contact />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
