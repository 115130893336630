import AppRoutes from './AppRoutes/AppRoutes'

import './App.css'

export function App() {
  return (
    <div className='app-container'>
      <AppRoutes />
    </div>
  )
}

export default App
